import React, { useEffect, useState } from "react";
import { Grid, TextField, Box, Typography } from "@mui/material";
import "./sixMonthForm.css";

const userReportData = {
    student_name: "",
    phone: "",
    email: "",
    bbId: "",
    collegeId: "",
    departmentName: "",
    noOfAssessmentsTaken: ""
}


function SixMonthReportForm({ propData, userObject }: any) {
    const [singleUserReportData, setSingleUserReportData] = useState(userReportData)
    useEffect(() => {
        if (propData) {
            const formattedData = {
                student_name: propData.firstName + " " + propData.lastName,
                phone: propData.phone,
                email: propData.email,
                bbId: propData.id,
                collegeId: propData?.rollNumber,
                departmentName: propData?.department?.name,
                noOfAssessmentsTaken: propData?.numberOfTestsTaken
            }
            setSingleUserReportData(formattedData);
        }
    }, [propData])
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container sx={{ padding: "10px" }} gap={1}>
                <Grid item xs={12} sm={4.8}>
                    <Typography sx={{ color: "#045139", fontFamily: "Maven Pro" }}>
                        {/* {form.name} */}
                        Student Name
                    </Typography>
                    <TextField
                        fullWidth
                        type="text"
                        name="College Code"
                        variant="outlined"
                        size="small"
                        value={singleUserReportData && singleUserReportData?.student_name === "" ? 'NA' : singleUserReportData?.student_name}
                        className="formField"
                        sx={{ input: { color: "#045139", fontWeight: "600" } }}
                    />
                </Grid>

                <Grid item xs={12} sm={4.8}>
                    <Typography sx={{ color: "#045139", fontFamily: "Maven Pro" }}>
                        Email
                    </Typography>
                    <TextField
                        fullWidth
                        type="text"
                        name="College Code"
                        variant="outlined"
                        size="small"
                        value={singleUserReportData && singleUserReportData?.email === "" ? 'NA' : singleUserReportData?.email}
                        className="formField"
                        sx={{ input: { color: "#045139", fontWeight: "600" } }}
                    />
                </Grid>

                <Grid item xs={12} sm={1.8}>
                    <Typography sx={{ color: "#045139", fontFamily: "Maven Pro" }}>
                        Blackbucks ID
                    </Typography>
                    <TextField
                        fullWidth
                        type="text"
                        name="College Code"
                        variant="outlined"
                        size="small"
                        value={userObject['userDetails']?.bbId === undefined ? 'NA' : userObject['userDetails']?.bbId}
                        className="formField"
                        sx={{ input: { color: "#045139", fontWeight: "600" } }}
                    />
                </Grid>
            </Grid>

            <Grid container sx={{ mt: 1, padding: "10px" }} gap={1}>
                <Grid item xs={12} sm={2.35}>
                    <Typography sx={{ color: "#045139", fontFamily: "Maven Pro" }}>
                        Contact Number
                    </Typography>
                    <TextField
                        fullWidth
                        type="text"
                        name="College Code"
                        variant="outlined"
                        size="small"
                        value={singleUserReportData && singleUserReportData?.phone === "" ? 'NA' : singleUserReportData?.phone}
                        className="formField"
                        sx={{ input: { color: "#045139", fontWeight: "600" } }}
                    />
                </Grid>

                <Grid item xs={12} sm={2.35}>
                    <Typography sx={{ color: "#045139", fontFamily: "Maven Pro" }}>
                        College Reg. ID
                    </Typography>
                    <TextField
                        fullWidth
                        type="text"
                        name="College Code"
                        variant="outlined"
                        size="small"
                        value={singleUserReportData && singleUserReportData?.collegeId === "" ? 'NA' : singleUserReportData?.collegeId}
                        className="formField"
                        sx={{ input: { color: "#045139", fontWeight: "600" } }}
                    />
                </Grid>

                <Grid item xs={12} sm={2.35}>
                    <Typography sx={{ color: "#045139", fontFamily: "Maven Pro" }}>
                        Branch
                    </Typography>
                    <TextField
                        fullWidth
                        type="text"
                        name="College Code"
                        variant="outlined"
                        size="small"
                        value={singleUserReportData && singleUserReportData?.departmentName === "" ? 'NA' : singleUserReportData?.departmentName}
                        className="formField"
                        sx={{ input: { color: "#045139", fontWeight: "600" } }}
                    />
                </Grid>

                {/* <Grid item xs={12} sm={2.35}>
                <Typography sx={{ color: "#045139", fontFamily: "Maven Pro" }}>
                    Level
                </Typography>
                <TextField
                    fullWidth
                    type="text"
                    name="College Code"
                    variant="outlined"
                    size="small"
                    value={propData.level}

                    sx={{ background: "#f9f9f9", mt: 1 }}
                />
                </Grid> */}

                <Grid item xs={12} sm={1.9}>
                    {/* <InputLabel sx={{color:'#045139'}}>No. of assessments taken</InputLabel> */}
                    <Typography
                        sx={{ color: "#045139", fontFamily: "Maven Pro sans-serif" }}
                    >
                        No.of assessments taken
                    </Typography>
                    <TextField
                        fullWidth
                        type="text"
                        name="College Code"
                        variant="outlined"
                        size="small"
                        value={!singleUserReportData.noOfAssessmentsTaken ? 'NA' : singleUserReportData?.noOfAssessmentsTaken}
                        className="formField"
                        sx={{ input: { color: "#045139", fontWeight: "600" } }}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}

export default SixMonthReportForm;
