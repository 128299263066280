import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material"
import "./sixMonthEachCard.css";
import { calculateEmployabilityBand } from "../../common/sixMonthReportScripts"


function truncateText(data: any) {
    var res: string = ""
    data?.map((val: string, i: number) => {
        if (i > 0) {
            res += ",";
        }
        res += val;
    })
    return res.substring(0, 25)+"..."
}


const SixMonthEachCard = ({ propsData, rows, sixMonthFormatData, swenData, formatedTotalScoreData, formatedEmployabilityScore }: any) => {
    const RenderAptitudeCodingEnglishCard = () => {
        return (
            <>
                {["Aptitude", "Coding", "English"].map((eachKey: string, index: number) => (
                    <TableRow className={index <= 1 ? "table-row" : ''}>
                        {sixMonthFormatData[eachKey] ? (
                            ['1', '2', '3', '4'].map((eachNextKey, weekIndex: number) => (
                                sixMonthFormatData[eachKey]?.[`${eachNextKey}-${propsData.monthValue}`] ? (
                                    <TableCell sx={{ fontWeight: 300 }} className={weekIndex <= 2 ? `tableBorder` : ""}>
                                        {sixMonthFormatData[eachKey]?.[`${eachNextKey}-${propsData.monthValue}`]}
                                    </TableCell>
                                ) : (<TableCell sx={{ fontWeight: 300 }} className={weekIndex <= 2 ? `tableBorder` : ""}>NA</TableCell>)
                            ))
                        ) : (
                            <>
                                <TableCell sx={{ fontWeight: 300 }} className="tableBorder">NA</TableCell>
                                <TableCell sx={{ fontWeight: 300 }} className="tableBorder">NA</TableCell>
                                <TableCell sx={{ fontWeight: 300 }} className="tableBorder">NA</TableCell>
                                <TableCell sx={{ fontWeight: 300 }}>NA</TableCell>
                            </>
                        )}
                    </TableRow>
                ))}
            </>
        )
    }

    const RenderTotalEmployabilityCard = () => {
        return (
            <>
                <TableRow className="table-row" sx={{ borderBottom: "1.5px solid  #045139" }}>
                    {formatedTotalScoreData ? (
                        ['1', '2', '3', '4'].map((eachNextKey, weekIndex: number) => (
                            formatedTotalScoreData?.[`${eachNextKey}-${propsData.monthValue}`] ? (
                                <TableCell sx={{ fontWeight: 300 }} className={weekIndex <= 2 ? `tableRow tableBorder` : "tableRow"}>
                                    {formatedTotalScoreData?.[`${eachNextKey}-${propsData.monthValue}`]}
                                </TableCell>
                            ) : (<TableCell sx={{ fontWeight: 300 }} className={weekIndex <= 2 ? `tableRow tableBorder` : "tableRow"}>NA</TableCell>)
                        ))
                    ) : (
                        <>
                            <TableCell sx={{ fontWeight: 300 }} className="tableBorder tableRow">NA</TableCell>
                            <TableCell sx={{ fontWeight: 300 }} className="tableBorder tableRow">NA</TableCell>
                            <TableCell sx={{ fontWeight: 300 }} className="tableBorder tableRow">NA</TableCell>
                            <TableCell sx={{ fontWeight: 300 }} className="tableRow">NA</TableCell>
                        </>
                    )}
                </TableRow>
                <TableRow >
                    {formatedEmployabilityScore ? (
                        <>
                            <TableCell sx={{ fontWeight: 100 }} className="tableBorder">{calculateEmployabilityBand(
                                formatedEmployabilityScore?.['Coding']?.[propsData.monthValue],
                                formatedEmployabilityScore?.['Aptitude']?.[propsData.monthValue],
                                formatedEmployabilityScore?.['English']?.[propsData.monthValue]
                            )}</TableCell>
                            <TableCell sx={{ fontWeight: 100 }} className="tableBorder"></TableCell>
                            <TableCell sx={{ fontWeight: 100 }} className="tableBorder"></TableCell>
                            <TableCell sx={{ fontWeight: 100 }}></TableCell>
                        </>
                    ) : (
                        <TableCell sx={{ fontWeight: 300 }} colSpan={12}>NA</TableCell>
                    )}
                </TableRow>
            </>
        )
    }

    const RenderTextCard = () => {
        return (
            <>
                {swenData ? (
                    <TableRow >
                        {swenData[`month${propsData.monthValue}`] && swenData[`month${propsData.monthValue}`].length > 0 ? (
                            <TableCell colSpan={12} sx={{ overflow: "auto" }}>
                                {truncateText(swenData?.[`month${propsData.monthValue}`])}
                            </TableCell>)
                            : (
                                <TableCell colSpan={12}>NA</TableCell>
                            )}
                    </TableRow>
                ) : (
                    <TableRow >
                        <TableCell colSpan={12}></TableCell>
                    </TableRow>
                )}
            </>
        )
    }

    return (
        <Box>
            {rows === 3 &&
                (<Typography align="center" className="month-label">
                    <Typography className="month-text" sx={{ backgroundColor: propsData.monthColor }} >{propsData.month}</Typography>
                </Typography>)}
            <TableContainer component={Paper} sx={{ backgroundColor: propsData.backgroundColor }} className="table-container">
                <Table sx={{ width: "15rem" }}>
                    <TableBody sx={{ height: "7rem" }}>
                        {rows === 3 && (<RenderAptitudeCodingEnglishCard />)}
                        {rows === 2 && (<RenderTotalEmployabilityCard />)}
                        {rows === 1 && (<RenderTextCard />)}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}

export default SixMonthEachCard