import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import "./studentRankingTable.css";

interface StudentData {
    StudentName: string;
    CollegeRegId: string;
    Phone: string;
    EmailId: string;
    CollegeName: string;
    "10th": string;
    "12th": string;
    UG: string;
    EmployabilityScore: number;
    CodingRank: number;
    NoOfTestsTaken: number;
    Aptitude: number;
    Coding: number;
    English: number;
    Top3TechSkills: string[];
    StrongAreas: string[];
}


const rowsData: StudentData[] = [
    {
        "StudentName": "John Doe",
        "CollegeRegId": "CR123456",
        "Phone": "123-456-7890",
        "EmailId": "john.doe@example.com",
        "CollegeName": "ABC University",
        "10th": "90%",
        "12th": "85%",
        "UG": "Computer Science",
        "EmployabilityScore": 85,
        "CodingRank": 2,
        "NoOfTestsTaken": 10,
        "Aptitude": 75,
        "Coding": 90,
        "English": 80,
        "Top3TechSkills": ["Python", "JavaScript", "SQL"],
        "StrongAreas": ["Problem Solving", "Data Structures"]
    },
    {
        "StudentName": "Jane Smith",
        "CollegeRegId": "CR789012",
        "Phone": "987-654-3210",
        "EmailId": "jane.smith@example.com",
        "CollegeName": "XYZ College",
        "10th": "92%",
        "12th": "88%",
        "UG": "Electrical Engineering",
        "EmployabilityScore": 78,
        "CodingRank": 5,
        "NoOfTestsTaken": 8,
        "Aptitude": 80,
        "Coding": 70,
        "English": 90,
        "Top3TechSkills": ["C", "MATLAB", "Embedded Systems"],
        "StrongAreas": ["Circuit Design", "Signals and Systems"]
    },
    {
        "StudentName": "Michael Johnson",
        "CollegeRegId": "CR567890",
        "Phone": "555-123-4567",
        "EmailId": "michael.johnson@example.com",
        "CollegeName": "LMN University",
        "10th": "85%",
        "12th": "80%",
        "UG": "Mechanical Engineering",
        "EmployabilityScore": 70,
        "CodingRank": 10,
        "NoOfTestsTaken": 5,
        "Aptitude": 60,
        "Coding": 75,
        "English": 70,
        "Top3TechSkills": ["AutoCAD", "SolidWorks", "Thermodynamics"],
        "StrongAreas": ["CAD Modeling", "Mechanical Design"]
    },
    {
        "StudentName": "Emily Brown",
        "CollegeRegId": "CR234567",
        "Phone": "333-444-5555",
        "EmailId": "emily.brown@example.com",
        "CollegeName": "PQR Institute",
        "10th": "88%",
        "12th": "92%",
        "UG": "Chemistry",
        "EmployabilityScore": 92,
        "CodingRank": 3,
        "NoOfTestsTaken": 12,
        "Aptitude": 85,
        "Coding": 85,
        "English": 95,
        "Top3TechSkills": ["Analytical Chemistry", "Spectroscopy", "Data Analysis"],
        "StrongAreas": ["Lab Techniques", "Chemical Research"]
    },
    {
        "StudentName": "William Lee",
        "CollegeRegId": "CR345678",
        "Phone": "777-888-9999",
        "EmailId": "william.lee@example.com",
        "CollegeName": "EFG College",
        "10th": "78%",
        "12th": "82%",
        "UG": "Economics",
        "EmployabilityScore": 68,
        "CodingRank": 15,
        "NoOfTestsTaken": 3,
        "Aptitude": 70,
        "Coding": 50,
        "English": 75,
        "Top3TechSkills": ["Data Analysis", "Excel", "Statistics"],
        "StrongAreas": ["Microeconomics", "Quantitative Analysis"]
    },
    {
        "StudentName": "Olivia Taylor",
        "CollegeRegId": "CR456789",
        "Phone": "222-333-4444",
        "EmailId": "olivia.taylor@example.com",
        "CollegeName": "UVW University",
        "10th": "95%",
        "12th": "90%",
        "UG": "Biology",
        "EmployabilityScore": 88,
        "CodingRank": 8,
        "NoOfTestsTaken": 7,
        "Aptitude": 82,
        "Coding": 60,
        "English": 88,
        "Top3TechSkills": ["Genetics", "Microbiology", "Research"],
        "StrongAreas": ["Lab Work", "Field Research"]
    }
]


const StudentRankingTable = () => {
    return (
        <Grid className="table-container" width="100%" p={2}>
            <TableContainer component={Paper}
                sx={{ height: 400, maxHeight: "30rem" }}>
                <Table sx={{
                    minWidth: 650,
                    borderCollapse: 'collapse',
                    width: '100%',
                    overflowX: 'auto',
                    whiteSpace: 'nowrap'
                }}
                    size="small"
                    aria-label="a dense table">
                    <TableHead>
                        <TableRow sx={{height:"3rem"}}>
                            <TableCell sx={{
                                fontFamily: 'Maven Pro',
                                fontWeight: 600,
                                position: "sticky",
                                left: 0,
                                backgroundColor: "white",
                                zIndex: "100",
                            }}>Student Name</TableCell>
                            <TableCell sx={{ fontFamily: "Maven Pro", fontWeight: 600 }}>College Reg Id</TableCell>
                            <TableCell sx={{ fontFamily: "Maven Pro", fontWeight: 600 }}>Phone</TableCell>
                            <TableCell sx={{ fontFamily: "Maven Pro", fontWeight: 600 }}>Email Id</TableCell>
                            <TableCell sx={{ fontFamily: "Maven Pro", fontWeight: 600 }}>College Name</TableCell>
                            <TableCell sx={{ fontFamily: "Maven Pro", fontWeight: 600 }}>10th</TableCell>
                            <TableCell sx={{ fontFamily: "Maven Pro", fontWeight: 600 }}>12th</TableCell>
                            <TableCell sx={{ fontFamily: "Maven Pro", fontWeight: 600 }}>UG</TableCell>
                            <TableCell sx={{ fontFamily: "Maven Pro", fontWeight: 600 }}>Employability Score</TableCell>
                            <TableCell sx={{ fontFamily: "Maven Pro", fontWeight: 600 }}>Coding Rank</TableCell>
                            <TableCell sx={{ fontFamily: "Maven Pro", fontWeight: 600 }}>No Of Tests Taken</TableCell>
                            <TableCell sx={{ fontFamily: "Maven Pro", fontWeight: 600 }}>Aptitude</TableCell>
                            <TableCell sx={{ fontFamily: "Maven Pro", fontWeight: 600 }}>Coding</TableCell>
                            <TableCell sx={{ fontFamily: "Maven Pro", fontWeight: 600 }}>English</TableCell>
                            <TableCell sx={{ fontFamily: "Maven Pro", fontWeight: 600 }}>Top 3 Tech Skills</TableCell>
                            <TableCell sx={{ fontFamily: "Maven Pro", fontWeight: 600 }}>Strong Areas</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rowsData.map((eachStudent: StudentData, index: number) => (
                            <TableRow
                                key={index}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } ,height:"3rem"}}
                            >
                                <TableCell component="th" scope="row">
                                    {eachStudent.StudentName}
                                </TableCell>
                                <TableCell> {eachStudent.CollegeRegId}</TableCell>
                                <TableCell > {eachStudent.Phone}</TableCell>
                                <TableCell> {eachStudent.EmailId}</TableCell>
                                <TableCell > {eachStudent.CollegeName}</TableCell>
                                <TableCell > {eachStudent["10th"]}</TableCell>
                                <TableCell > {eachStudent["12th"]}</TableCell>
                                <TableCell > {eachStudent["UG"]}</TableCell>
                                <TableCell > {eachStudent.EmployabilityScore}</TableCell>
                                <TableCell > {eachStudent.CodingRank}</TableCell>
                                <TableCell > {eachStudent.NoOfTestsTaken}</TableCell>
                                <TableCell > {eachStudent.Aptitude}</TableCell>
                                <TableCell > {eachStudent.Coding}</TableCell>
                                <TableCell > {eachStudent.English}</TableCell>
                                <TableCell > {eachStudent.Top3TechSkills}</TableCell>
                                <TableCell > {eachStudent.StrongAreas}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    )
}

export default StudentRankingTable