import {
    Grid,
    Typography,
    Box,
    MenuItem,
    Select,
    FormControl,
    RadioGroup,
    Radio,
    FormControlLabel,
    Button,
    Paper,
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Autocomplete,
    TextField,
} from "@mui/material";
import Images from "../../assets/images";
import { useNavigate } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { useAppSelector } from "../../redux/hooks/hooks";
import {
    fetchReports,
    fetchScreeningResultsData,
    fetchSixMonthReport,
    fetchUserData,
} from "../../redux/actions/reportsAction";
import { useAppDispatch } from "../../redux/hooks/hooks";
import dayjs from "dayjs";
import useLazyLoading from "../../hooks/lazyLoadHook";
import { setReportEndMonth } from "../../redux/reducers/reportsReducer";
import { GetApp } from "@mui/icons-material";
import "./dashboardTable.css";
import { getSixMonthReportCsv } from "../../apis/api";
import ProfilingModal from "../ProfilingModal/ProfilingModal";
import SixMonthReportModal from "../SixMonthReportModal/SixMonthReportModal";
import { downloadSixMonthReport } from "../../common/sixMonthReportScripts"


interface College {
    id: number;
    name: string;
    code: string;
}

interface EducationDetail {
    id: number;
    user: string;
    stage: string;
    degree: string | null;
    percentage: string;
}


interface User {
    id: string;
    firstName: string;
    lastName: string;
    rollNumber:string;
    email: string;
    phone: string | null;
    college: {
        id: number;
        name: string;
        code: string;
    };
    department: {
        id: number;
        name: string;
    };
    educationDetails: EducationDetail[];
    finalScore: string;
    numberOfTestsTaken: number;
    improvementFromLastTest: number;
    improvementFromFirstTest: number;
    userMonthlyScores: {
        [month: string]: number;
    };
}



function DashboardTable() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const reportError = useAppSelector(
        (state) => state.reportsReducer.reportErrorMsg
    );
    const reportLoading = useAppSelector((state) => state.reportsReducer.loading);
    const collegeData = useAppSelector((state) => state.reportsReducer.colleges);
    const reportsData = useAppSelector(
        (state) => state.reportsReducer.reportsData
    );
    const reportMonths = useAppSelector(
        (state) => state.reportsReducer.reportEndMonth
    );
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const [ref, visible] = useLazyLoading({});
    const [collegeId, setCollegeId] = useState(1);
    const [startMonth, setStartMonth] = useState("0");
    const [endMonth, setEndMonth] = useState("5");
    const [dateValue, setDateValue] = useState(dayjs().month(5).valueOf());
    const [monthNames, setMonthNames] = useState<string[]>([]);
    const [isIntersecting, setIsIntersecting] = useState(false);
    const [selectedReportType, setSelectedReportType] =
        useState("6 month report");
    const [skip, setSkip] = useState(0);
    const [monthNamesValue, setMonthNamesValue] = useState<string[]>([]);
    const [open, setOpen] = useState(false);
    const [selectedUserRowId, setSelectedUserRowId] = useState(Number);
    const [downloadExcelLoading, setDownloadExcelLoading] = useState(false);
    const [loadMore, setLoadMore] = useState(false);
    const [isZip, setIsZip] = useState(false);
    const screeningResults = useAppSelector(
        (state) => state.reportsReducer.screeningResults
    )

    const handleChange = (e: any) => {
        setSelectedReportType(e.target.value);
    };

    const handleView = (id: string) => {
        switch (selectedReportType) {
            case "6 month report":
                dispatch(fetchSixMonthReport(dateValue, +id));
                dispatch(fetchUserData(+id));
                break;
            case "BB Lab Report":
                navigate("/labreport");
                break;
            case "Screening Result":
                //navigate("/profiling");
                dispatch(fetchUserData(+id));
                break;
        }
        setSelectedUserRowId(+id);
        handleOpen();
    };

    const handleDownload = async (id: string, rowData: any) => {
        switch (selectedReportType) {
            case "6 month report":
                downloadSixMonthReport(dateValue, +id, rowData, reportMonths);
                break;
            case "BB Lab Report":
                navigate("/labreport");
                break;
            case "Screening Result":
                //navigate("/profiling");
                break;
        }
    };

    const sixMonthWorker: Worker = useMemo(
        () => new Worker(new URL("../../webworkers/sixMonthWorker.ts", import.meta.url)),
        []
    );


    const handleZipFiles = async () => {
        setIsZip(true);
        const data: any[] = [];
        for (let i = 0; i < 100; i++) {
            data.push({
                "id": "9180",
                "skillGroups": {
                    "Aptitude": [
                        {
                            "roundId": 30,
                            "scorePercentage": "83.3",
                            "weekOfMonth": 5,
                            "monthOfYear": 2
                        },
                        {
                            "roundId": 118,
                            "scorePercentage": "100.0",
                            "weekOfMonth": 4,
                            "monthOfYear": 3
                        },
                        {
                            "roundId": 145,
                            "scorePercentage": "100.0",
                            "weekOfMonth": 4,
                            "monthOfYear": 3
                        },
                        {
                            "roundId": 213,
                            "scorePercentage": "93.3",
                            "weekOfMonth": 5,
                            "monthOfYear": 3
                        },
                        {
                            "roundId": 354,
                            "scorePercentage": "76.7",
                            "weekOfMonth": 3,
                            "monthOfYear": 4
                        },
                        {
                            "roundId": 416,
                            "scorePercentage": "56.7",
                            "weekOfMonth": 5,
                            "monthOfYear": 5
                        }
                    ],
                    "English": [
                        {
                            "roundId": 34,
                            "scorePercentage": "31.3",
                            "weekOfMonth": 5,
                            "monthOfYear": 2
                        },
                        {
                            "roundId": 119,
                            "scorePercentage": "100.0",
                            "weekOfMonth": 4,
                            "monthOfYear": 3
                        },
                        {
                            "roundId": 215,
                            "scorePercentage": "22.5",
                            "weekOfMonth": 5,
                            "monthOfYear": 3
                        },
                        {
                            "roundId": 356,
                            "scorePercentage": "40.0",
                            "weekOfMonth": 3,
                            "monthOfYear": 4
                        },
                        {
                            "roundId": 417,
                            "scorePercentage": "52.5",
                            "weekOfMonth": 5,
                            "monthOfYear": 5
                        }
                    ],
                    "Coding": [
                        {
                            "roundId": 35,
                            "scorePercentage": "100.0",
                            "weekOfMonth": 5,
                            "monthOfYear": 2
                        },
                        {
                            "roundId": 146,
                            "scorePercentage": "100.0",
                            "weekOfMonth": 4,
                            "monthOfYear": 3
                        },
                        {
                            "roundId": 214,
                            "scorePercentage": "20.0",
                            "weekOfMonth": 5,
                            "monthOfYear": 3
                        },
                        {
                            "roundId": 240,
                            "scorePercentage": "100.0",
                            "weekOfMonth": 4,
                            "monthOfYear": 3
                        },
                        {
                            "roundId": 355,
                            "scorePercentage": "35.0",
                            "weekOfMonth": 3,
                            "monthOfYear": 4
                        },
                        {
                            "roundId": 419,
                            "scorePercentage": "0.0",
                            "weekOfMonth": 5,
                            "monthOfYear": 5
                        }
                    ]
                },
                "totalHackathonScore": [
                    {
                        "week_of_month": 5,
                        "month_of_year": 2,
                        "score": "63.89"
                    },
                    {
                        "week_of_month": 4,
                        "month_of_year": 3,
                        "score": "100.00"
                    },
                    {
                        "week_of_month": 5,
                        "month_of_year": 3,
                        "score": "45.56"
                    },
                    {
                        "week_of_month": 3,
                        "month_of_year": 4,
                        "score": "51.11"
                    },
                    {
                        "week_of_month": 5,
                        "month_of_year": 5,
                        "score": "42.22"
                    }
                ],
                "employabilityBand": {
                    "Aptitude": {
                        "2023-02": {
                            "yearMonth": "2023-02",
                            "total_round_score": 300,
                            "total_user_score": 250
                        },
                        "2023-03": {
                            "yearMonth": "2023-03",
                            "total_round_score": 300,
                            "total_user_score": 280
                        },
                        "2023-04": {
                            "yearMonth": "2023-04",
                            "total_round_score": 300,
                            "total_user_score": 230
                        },
                        "2023-05": {
                            "yearMonth": "2023-05",
                            "total_round_score": 300,
                            "total_user_score": 170
                        }
                    },
                    "English": {
                        "2023-02": {
                            "yearMonth": "2023-02",
                            "total_round_score": 400,
                            "total_user_score": 125
                        },
                        "2023-03": {
                            "yearMonth": "2023-03",
                            "total_round_score": 400,
                            "total_user_score": 90
                        },
                        "2023-04": {
                            "yearMonth": "2023-04",
                            "total_round_score": 400,
                            "total_user_score": 160
                        },
                        "2023-05": {
                            "yearMonth": "2023-05",
                            "total_round_score": 400,
                            "total_user_score": 210
                        }
                    },
                    "Coding": {
                        "2023-02": {
                            "yearMonth": "2023-02",
                            "total_round_score": 200,
                            "total_user_score": 200
                        },
                        "2023-03": {
                            "yearMonth": "2023-03",
                            "total_round_score": 200,
                            "total_user_score": 40
                        },
                        "2023-04": {
                            "yearMonth": "2023-04",
                            "total_round_score": 200,
                            "total_user_score": 70
                        },
                        "2023-05": {
                            "yearMonth": "2023-05",
                            "total_round_score": 200,
                            "total_user_score": 0
                        }
                    }
                },
                "strengths": {
                    "month2": [
                        "Ages",
                        "Analogy",
                        "Antonyms",
                        "Arrays",
                        "Basics of String Manipulation",
                        "Blood Relations",
                        "Boats and Streams",
                        "Calendar",
                        "Cause and Effect",
                        "Clocks",
                        "Data Structures",
                        "Direct and Indirect Speech",
                        "Image Series",
                        "Letter Series",
                        "Number series",
                        "One word substitutes",
                        "Ordering of words",
                        "Percentages",
                        "Permutation and Combination",
                        "Ratios and Proportions",
                        "Reading Passages",
                        "Sentence Completion",
                        "Sentence Ordering",
                        "Statement Analysis",
                        "Statements and Arguments",
                        "Substring",
                        "Time and Work",
                        "Venn Diagrams",
                        "Verbal Analogies"
                    ],
                    "month3": [
                        "Ages",
                        "Amount",
                        "Analogies",
                        "Area",
                        "area of trapezium",
                        "Arrays",
                        "Artificial Language",
                        "Average",
                        "Binary Search",
                        "Character Puzzles",
                        "Chose the correct",
                        "Classification",
                        "Codes",
                        "coding",
                        "Compound Interest",
                        "Datahandling",
                        "Days",
                        "Dice",
                        "Direction and Distance",
                        "Distance-time",
                        "Division",
                        "Error spotting",
                        "FILL IN THE BLANKS",
                        "Letter Series",
                        "Logical Games",
                        "Logical Problems",
                        "Making Judgements",
                        "Numbers",
                        "Numercial Ability",
                        "Output Code",
                        "Pattern",
                        "percentage",
                        "percentage",
                        "Percentage",
                        "Profit percentage",
                        "Radius of cylinder",
                        "Ratio and proportion",
                        "Ratio proportion",
                        "Rows and Colours",
                        "SDLC MODEL",
                        "Seating Arrangement",
                        "Series",
                        "Series Completion",
                        "Simple Interest",
                        "Simplification",
                        "Stacks",
                        "Stacks",
                        "Statement and Assumption",
                        "Statements and Arguments",
                        "Time and Work"
                    ],
                    "month4": [
                        "Alphabet Sequence",
                        "Analogies",
                        "Blood Relations",
                        "character",
                        "Coding and Decoding",
                        "Order and Ranking",
                        "Percentages",
                        "Permutation and Combination",
                        "Reading Comprehension",
                        "Syllogism",
                        "Time and Work"
                    ],
                    "month5": [
                        "Boats and Streams",
                        "Listening",
                        "Mensuration",
                        "Number System",
                        "Reading Comprehension"
                    ]
                },
                "weaknesses": {
                    "month2": [
                        "Classification",
                        "English",
                        "Essay Writing",
                        "Height and Distance",
                        "Listening",
                        "Partnerships",
                        "sentence Jumbles",
                        "Speaking"
                    ],
                    "month3": [
                        "Analyzing Arguments",
                        "Bubble Sort",
                        "Email Writing",
                        "Essay Writing",
                        "honei",
                        "Listening",
                        "Reading Passages"
                    ],
                    "month4": [
                        "Essay Writing",
                        "Input and Output",
                        "largest sum",
                        "product of array",
                        "unqiue ways"
                    ],
                    "month5": [
                        "Arrays",
                        "Bit Manipulation",
                        "Essay Writing",
                        "Geometry",
                        "Linked List",
                        "Probability",
                        "Sorting"
                    ]
                },
                "needsImprovement": {
                    "month2": [
                        "SI and CI"
                    ],
                    "month3": [
                        "Theme Detection"
                    ],
                    "month4": [
                        "Calenders",
                        "Decision Making",
                        "Listening",
                        "Profit and Loss",
                        "Puzzles",
                        "Seating Arrangement"
                    ],
                    "month5": [
                        "Indices and Surds"
                    ]
                }
            })
        }
        const sentPayload = { data: data, monthData: reportMonths }
        sixMonthWorker.onmessage = (event) => {
            const { type, payload } = event.data;
            if (type === 'excelGenerationComplete') {
                console.log('excelGenerationComplete');
                sixMonthWorker.postMessage({ type: 'zipExcels' })
            }
            if (type == "excelReady") {
                console.log('excelReady:', payload);
            }
            if (type === 'zipExcelsComplete') {
                console.log('zipExcelsComplete:', payload);
                const zipBlobUrl = URL.createObjectURL(payload);
                const a = document.createElement('a');
                a.href = zipBlobUrl;
                a.download = 'sixMonthZip.zip';
                a.click();
                URL.revokeObjectURL(zipBlobUrl);
                setIsZip(false)
            }
        };
        sixMonthWorker.postMessage({ type: 'generateExcel', sentPayload });
    };

    const getMonth = (end: number) => {
        const arr = [];
        const arr1 = [];
        const fullMonths = [];

        for (let i = 0; i < 6; i++) {
            arr.push(
                dayjs()
                    .month(end - i)
                    .format("MMM")
            );
        }
        for (let i = 0; i < 6; i++) {
            fullMonths.push(
                dayjs()
                    .month(end - i)
                    .format("MMMM")
            );
        }
        for (let i = 0; i < 6; i++) {
            arr1.push(
                dayjs()
                    .month(end - i)
                    .format("YYYY-MM")
            );
        }
        setMonthNames(arr);
        setMonthNamesValue(arr1);
        return fullMonths.reverse();
    };

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    // useEffect(() => {
    //   if (visible && reportError.length === 0) {
    //     setLoadMore(true);
    //     setSkip(skip + 4);
    //     dispatch(fetchReports(collegeId, dateValue, skip + 4, 4));
    //     setLoadMore(false);
    //   }
    // }, [visible]);

    useEffect(() => {
        dispatch(fetchReports(collegeId, dateValue, 0, 4));
        dispatch(setReportEndMonth(getMonth(+endMonth)));
        dispatch(fetchScreeningResultsData(collegeId, dateValue))
    }, []);

    const [searchText, setSearchText] = useState("other");

    const handleSearchChange = (event: any) => {
        setSearchText(event.target.value);
    };

    const filteredCollegeData = collegeData.collegedata.filter((item) =>
        item.name.toLowerCase().includes(searchText.toLowerCase())
    );

    const handleDownloadExcel = async () => {
        const csvData = (await getSixMonthReportCsv(collegeId, dateValue)).data;
        const blob = new Blob([csvData], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'sixMonthReportData.csv';
        a.click();
        URL.revokeObjectURL(url);
    }

    const renderEducationData = (stage: string, data: EducationDetail[]) => {
        for (const eachData of data) {
            if (eachData.stage === stage) {
                return eachData.percentage;
            }
        }
        return "NA";
    };

    const RenderSixMonthReport = () => {
        return (
            <>
                {selectedReportType === '6 month report' && (
                    reportLoading || loadMore ? (
                        <TableRow>
                            <TableCell colSpan={15}>
                                <Box display="flex" justifyContent="center">
                                    <Typography alignItems="center">Loading...</Typography>
                                </Box>
                            </TableCell>
                        </TableRow>
                    ) : (
                        <>
                            {reportsData.length > 0 ? (
                                <>
                                    {reportsData.map((eachReport: User, index: number) => (
                                        <>
                                            <TableRow key={index}>
                                                <TableCell sx={{
                                                    position: 'sticky',
                                                    left: 0,
                                                    backgroundColor: "white",
                                                    zIndex: "100",
                                                }}>{eachReport.firstName + " " + eachReport.lastName}</TableCell>
                                                <TableCell>{!eachReport.rollNumber ? "NA" : eachReport.rollNumber}</TableCell>
                                                <TableCell>{!eachReport.phone ? "NA" : eachReport.phone}</TableCell>
                                                <TableCell>{!eachReport.email ? "NA" : eachReport.email}</TableCell>
                                                <TableCell sx={{ whiteSpace: "nowrap" }}>{!eachReport.college.name ? "NA" : eachReport.college.name}</TableCell>
                                                <TableCell>{renderEducationData("Tenth", eachReport.educationDetails)}</TableCell>
                                                <TableCell>{renderEducationData("Twelfth", eachReport.educationDetails)}</TableCell>
                                                <TableCell>{renderEducationData("Degree", eachReport.educationDetails)}</TableCell>
                                                <TableCell>{!eachReport.finalScore ? "NA" : eachReport.finalScore}</TableCell>
                                                <TableCell>NA</TableCell>
                                                <TableCell>{eachReport.numberOfTestsTaken}</TableCell>
                                                <TableCell>{eachReport?.improvementFromFirstTest}</TableCell>
                                                <TableCell>{eachReport?.improvementFromLastTest}</TableCell>
                                                {
                                                    monthNames.length > 0 && monthNamesValue.map((eachMonth) => (
                                                        <TableCell>{!eachReport.userMonthlyScores[eachMonth] ? "NA" : eachReport.userMonthlyScores[eachMonth]}</TableCell>
                                                    ))
                                                }
                                                <TableCell sx={{ display: "flex", mt: 1 }}>
                                                    <Button
                                                        sx={{
                                                            mr: 1,
                                                            background: "#2E72F5",
                                                            borderRadius: "5px",
                                                        }}
                                                        variant="contained"
                                                        onClick={() => handleView(eachReport.id)}
                                                    >
                                                        View
                                                    </Button>
                                                    <Button
                                                        sx={{
                                                            mr: 1,
                                                            background: "#009950",
                                                            borderRadius: "5px",
                                                        }}
                                                        variant="contained"
                                                        onClick={() => handleDownload(eachReport.id, eachReport)}
                                                    >
                                                        Download
                                                    </Button>

                                                </TableCell>
                                            </TableRow>
                                        </>
                                    ))}
                                </>
                            ) : (
                                <>
                                    <TableRow>
                                        <TableCell colSpan={20}>
                                            <Box display="flex" flexDirection="row" justifyContent="center">
                                                <Typography
                                                    paddingTop={3}
                                                    paddingBottom={3}
                                                >
                                                    {reportError === "" && reportsData.length === 0 ? "No Data Found!" : reportError}
                                                </Typography>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                </>
                            )}
                        </>
                    )
                )}
            </>
        )
    }

    const RenderScreeningResults = () => {
        return (
            <>
                {selectedReportType === 'Screening Result' && (
                    screeningResults.loading ? (
                        <TableRow>
                            <TableCell colSpan={15}>
                                <Box display="flex" justifyContent="center">
                                    <Typography alignItems="center">Loading...</Typography>
                                </Box>
                            </TableCell>
                        </TableRow>
                    ) : (
                        <>
                            {screeningResults.screeningData.length > 0 ? (
                                <>
                                    {screeningResults.screeningData.map((eachResult: any, index: number) => (
                                        <>
                                            <TableRow key={index}>
                                                <TableCell sx={{
                                                    position: 'sticky',
                                                    left: 0,
                                                    backgroundColor: "white",
                                                    zIndex: "100",
                                                }}>{eachResult.firstName + " " + eachResult.lastName}</TableCell>
                                                <TableCell>{!eachResult.rollNumber ? "NA" : eachResult.rollNumber}</TableCell>
                                                <TableCell>{!eachResult.phone ? "NA" : eachResult.phone}</TableCell>
                                                <TableCell>{!eachResult.email ? "NA" : eachResult.email}</TableCell>
                                                <TableCell sx={{ whiteSpace: "nowrap" }}>{!eachResult.college.name ? "NA" : eachResult.college.name}</TableCell>
                                                <TableCell>{renderEducationData("Tenth", eachResult.educationDetails)}</TableCell>
                                                <TableCell>{renderEducationData("Twelfth", eachResult.educationDetails)}</TableCell>
                                                <TableCell>{renderEducationData("Degree", eachResult.educationDetails)}</TableCell>
                                                <TableCell>{!eachResult.totalScore ? "NA" : eachResult.totalScore}</TableCell>
                                                <TableCell>NA</TableCell>
                                                <TableCell>{!eachResult.aptitude ? "NA" : eachResult.aptitude}</TableCell>
                                                <TableCell>{!eachResult.coding ? "NA" : eachResult.coding}</TableCell>
                                                <TableCell>{!eachResult.english ? "NA" : eachResult.english}</TableCell>
                                                <TableCell sx={{ display: "flex", mt: 1 }}>
                                                    <Button
                                                        sx={{
                                                            mr: 1,
                                                            background: "#2E72F5",
                                                            borderRadius: "5px",
                                                        }}
                                                        variant="contained"
                                                        onClick={() => handleView(eachResult.id)}
                                                    >
                                                        View
                                                    </Button>
                                                    <Button
                                                        sx={{
                                                            mr: 1,
                                                            background: "#009950",
                                                            borderRadius: "5px",
                                                        }}
                                                        variant="contained"
                                                        onClick={() => handleDownload(eachResult.id, eachResult)}
                                                    >
                                                        Download
                                                    </Button>

                                                </TableCell>
                                            </TableRow>
                                        </>
                                    ))}
                                </>
                            ) : (
                                <>
                                    <TableRow>
                                        <TableCell colSpan={20}>
                                            <Box display="flex" flexDirection="row" justifyContent="center">
                                                <Typography
                                                    paddingTop={3}
                                                    paddingBottom={3}
                                                >
                                                    {reportError === "" && screeningResults.screeningData.length === 0 ? "No Data Found!" : reportError}
                                                </Typography>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                </>
                            )}
                        </>
                    )
                )}
            </>
        )
    }

    return (
        <Box
            sx={{
                backgroundImage: `url(${Images.tableBG})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                mt: 8,
                borderRadius: "20px",
            }}
        >
            <Grid container sx={{ mt: 5 }}>
                <Grid item sx={{ width: "100%" }}>
                    <Grid pt={3} px={2} pb={3}>
                        <Grid>
                            <Typography
                                variant="h4"
                                sx={{ color: "white", fontWeight: "800", pl: 1, fontSize: "2rem" }}
                            >
                                Download Student Report here...
                            </Typography>
                        </Grid>

                        <Grid container spacing={2} sx={{ p: 2 }}>
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={3}
                                sx={{
                                    display: "flex",
                                    flexDirection: { xs: "column", sm: "row" },
                                }}
                            >
                                <Typography sx={{ color: "white" }} variant="body2">
                                    <pre style={{ fontFamily: "Maven Pro" }}> College : </pre>
                                </Typography>
                                <FormControl sx={{ width: "100%" }}>
                                    <Autocomplete
                                        id="search-select"
                                        size="small"
                                        autoComplete
                                        autoHighlight
                                        sx={{
                                            color: "black",
                                            background: "white",
                                            fontFamily: "Maven Pro",
                                            borderRadius: "5px",
                                            padding: '0px'
                                        }}
                                        options={filteredCollegeData}
                                        getOptionLabel={(option) => option.name}
                                        value={filteredCollegeData.find((item) => item.id === collegeId) || null}
                                        onChange={(event, newValue) => {
                                            if (newValue) {
                                                setCollegeId(newValue.id);
                                                setSkip(0);
                                                dispatch(fetchReports(newValue.id, dateValue, 0, 4));
                                                dispatch(fetchScreeningResultsData(newValue.id, dateValue))
                                                setSearchText(newValue.name);
                                            }
                                        }}
                                        inputValue={searchText}
                                        disableClearable
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                value={searchText}
                                                onChange={handleSearchChange}
                                            />
                                        )}
                                    />

                                </FormControl>
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={2}
                                sx={{
                                    display: "flex",
                                    flexDirection: { xs: "column", sm: "row" },
                                }}
                            >
                                <Typography sx={{ color: "white" }} variant="body2">
                                    <pre style={{ fontFamily: "Maven Pro" }}> Start Month : </pre>
                                </Typography>
                                <FormControl sx={{ width: "100%" }}>
                                    <Select
                                        value={startMonth}
                                        defaultValue="jan"
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        sx={{
                                            color: "black",
                                            background: "white",
                                            height: "40px",
                                            fontFamily: "Maven Pro",
                                        }}
                                        disabled={true}
                                    >
                                        <MenuItem value="0">January</MenuItem>
                                        <MenuItem value="1">February</MenuItem>
                                        <MenuItem value="2">March</MenuItem>
                                        <MenuItem value="3">April</MenuItem>
                                        <MenuItem value="4">May</MenuItem>
                                        <MenuItem value="5">June</MenuItem>
                                        <MenuItem value="6">July</MenuItem>
                                        <MenuItem value="7">August</MenuItem>
                                        <MenuItem value="8">September</MenuItem>
                                        <MenuItem value="9">October</MenuItem>
                                        <MenuItem value="10">November</MenuItem>
                                        <MenuItem value="11">December</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={2}
                                sx={{
                                    display: "flex",
                                    flexDirection: { xs: "column", sm: "row" },
                                }}
                            >
                                <Typography sx={{ color: "white" }} variant="body2">
                                    <pre style={{ fontFamily: "Maven Pro" }}> End Month : </pre>
                                </Typography>
                                <FormControl sx={{ width: "100%" }}>
                                    <Select
                                        value={endMonth}
                                        defaultValue="jun"
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        sx={{
                                            color: "black",
                                            background: "white",
                                            height: "40px",
                                            fontFamily: "Maven Pro",
                                        }}
                                        disabled={collegeId === 0}
                                        onChange={(e) => {
                                            const date_value = dayjs()
                                                .month(+e.target.value)
                                                .valueOf();
                                            dispatch(setReportEndMonth(getMonth(+e.target.value)));
                                            setDateValue(date_value);
                                            setEndMonth(e.target.value);
                                            getMonth(+e.target.value);
                                            setStartMonth(() =>
                                                +e.target.value - 5 < 0
                                                    ? `${+e.target.value + 7}`
                                                    : `${+e.target.value - 5}`
                                            );
                                            setSkip(0);
                                            dispatch(fetchReports(collegeId, date_value, 0, 4));
                                            dispatch(fetchScreeningResultsData(collegeId, date_value))
                                        }}
                                    >
                                        <MenuItem value="0">January</MenuItem>
                                        <MenuItem value="1">February</MenuItem>
                                        <MenuItem value="2">March</MenuItem>
                                        <MenuItem value="3">April</MenuItem>
                                        <MenuItem value="4">May</MenuItem>
                                        <MenuItem value="5">June</MenuItem>
                                        <MenuItem value="6">July</MenuItem>
                                        <MenuItem value="7">August</MenuItem>
                                        <MenuItem value="8">September</MenuItem>
                                        <MenuItem value="9">October</MenuItem>
                                        <MenuItem value="10">November</MenuItem>
                                        <MenuItem value="11">December</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid
                            item
                            sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                                mt: 3,
                                color: "white",
                            }}
                        >
                            <Grid sx={{ pl: 3 }}>
                                <FormControl>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        value={selectedReportType}
                                        onChange={handleChange}
                                    >
                                        <FormControlLabel
                                            value="6 month report"
                                            control={
                                                <Radio
                                                    sx={{
                                                        color: "white",
                                                        "&.Mui-checked": { color: "white" },
                                                    }}
                                                />
                                            }
                                            label="6 month report"
                                        />
                                        {/* <FormControlLabel
                                                value="BB Lab Report"
                                                control={
                                                <Radio
                                                    sx={{
                                                    color: "white",
                                                    "&.Mui-checked": { color: "white" },
                                                    }}
                                                />
                                                }
                                                label="BB Lab Report"
                                            /> 
                                        */}
                                        <FormControlLabel
                                            value="Screening Result"
                                            control={
                                                <Radio
                                                    sx={{
                                                        color: "white",
                                                        "&.Mui-checked": { color: "white" },
                                                    }}
                                                />
                                            }
                                            label="Screening Result"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid>
                                <Button
                                    sx={{
                                        fontFamily: "Maven Pro",
                                        background: "#009950",
                                        m: 1,
                                        color: "white",
                                        "&:hover": { background: "#009950" },
                                    }}
                                    size="small"
                                    onClick={handleZipFiles}
                                    disabled={isZip}
                                >
                                    {isZip ? "Processing" : " Download Zip"} <GetApp fontSize="small"></GetApp>
                                </Button>
                                {selectedReportType === '6 month report' ? (<Button
                                    sx={{
                                        fontFamily: "Maven Pro",
                                        background: "#2E72F5",
                                        m: 1,
                                        color: "white",
                                        "&:hover": { background: "#2E52F5" },
                                    }}
                                    size="small"
                                    onClick={handleDownloadExcel}
                                >
                                    Download Excel <GetApp fontSize="small"></GetApp>
                                </Button>) : ""}
                            </Grid>
                        </Grid>

                        <Grid sx={{ width: "100%" }}>
                            <TableContainer
                                component={Paper}
                                sx={{ height: 400, maxHeight: "30rem" }}
                            >
                                <Table
                                    sx={{
                                        minWidth: 650,
                                        borderCollapse: 'collapse',
                                        width: '100%',
                                        overflowX: 'auto',
                                    }}
                                    size="small"
                                    aria-label="a dense table"
                                >
                                    <TableHead
                                    >
                                        <TableRow>
                                            <TableCell
                                                sx={{
                                                    fontFamily: 'Maven Pro',
                                                    fontWeight: 900,
                                                    position: "sticky",
                                                    left: 0,
                                                    backgroundColor: "white",
                                                    zIndex: "100",
                                                }}
                                            >
                                                Student_Name
                                            </TableCell>
                                            <TableCell
                                                sx={{ fontFamily: "Maven Pro", fontWeight: 900 }}
                                            >
                                                College_Reg_Id
                                            </TableCell>
                                            <TableCell
                                                sx={{ fontFamily: "Maven Pro", fontWeight: 900 }}
                                            >
                                                Phone
                                            </TableCell>
                                            <TableCell
                                                sx={{ fontFamily: "Maven Pro", fontWeight: 900 }}
                                            >
                                                Email_Id
                                            </TableCell>
                                            <TableCell
                                                sx={{ fontFamily: "Maven Pro", fontWeight: 900 }}
                                            >
                                                College_Name
                                            </TableCell>
                                            <TableCell
                                                sx={{ fontFamily: "Maven Pro", fontWeight: 900 }}
                                            >
                                                10th
                                            </TableCell>
                                            <TableCell
                                                sx={{ fontFamily: "Maven Pro", fontWeight: 900 }}
                                            >
                                                12th
                                            </TableCell>
                                            <TableCell
                                                sx={{ fontFamily: "Maven Pro", fontWeight: 900 }}
                                            >
                                                UG
                                            </TableCell>
                                            <TableCell
                                                sx={{ fontFamily: "Maven Pro", fontWeight: 900 }}
                                            >
                                                Final_Score
                                            </TableCell>
                                            <TableCell
                                                sx={{ fontFamily: "Maven Pro", fontWeight: 900 }}
                                            >
                                                Coding_Rank
                                            </TableCell>
                                            {selectedReportType === "6 month report" && (
                                                <>
                                                    <TableCell
                                                        sx={{ fontFamily: "Maven Pro", fontWeight: 900 }}
                                                    >
                                                        No._of_tests_taken
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{ fontFamily: "Maven Pro", fontWeight: 900 }}
                                                    >
                                                        %Improvement_from_first_test
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{ fontFamily: "Maven Pro", fontWeight: 900 }}
                                                    >
                                                        %Improvement_from_last_test
                                                    </TableCell>
                                                    {monthNames.length > 0
                                                        ? monthNames.map((item) => (
                                                            <TableCell
                                                                sx={{
                                                                    fontFamily: "Maven Pro",
                                                                    fontWeight: 900,
                                                                }}
                                                            >
                                                                {item}
                                                            </TableCell>
                                                        ))
                                                        : ["Jun", "May", "Apr", "Mar", "Feb", "Jan"].map(
                                                            (item) => (
                                                                <TableCell
                                                                    sx={{
                                                                        fontFamily: "Maven Pro",
                                                                        fontWeight: 900,
                                                                    }}
                                                                >
                                                                    {item}
                                                                </TableCell>
                                                            )
                                                        )}
                                                </>
                                            )}
                                            {selectedReportType === "Screening Result" && (
                                                <>
                                                    <TableCell
                                                        sx={{ fontFamily: "Maven Pro", fontWeight: 900 }}
                                                    >
                                                        Aptitude
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{ fontFamily: "Maven Pro", fontWeight: 900 }}
                                                    >
                                                        Coding
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{ fontFamily: "Maven Pro", fontWeight: 900 }}
                                                    >
                                                        English
                                                    </TableCell>
                                                </>
                                            )}
                                            <TableCell
                                                align="center"
                                                sx={{ fontFamily: "Maven Pro", fontWeight: 900 }}
                                            >
                                                Report
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <RenderSixMonthReport />
                                        <RenderScreeningResults />
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {selectedReportType === "6 month report" && (<SixMonthReportModal modalOpen={open} selectedUserRowId={selectedUserRowId} handleClose={handleClose} />)}
            {selectedReportType === "Screening Result" && (<ProfilingModal modalOpen={open} selectedUserRowId={selectedUserRowId} handleClose={handleClose} />)}
        </Box>
    );
}

export default DashboardTable;
