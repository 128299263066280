import { Box, Button, Checkbox, Divider, FormControlLabel, FormGroup, Grid, InputAdornment, Popper, Slider, Stack, TextField, Typography } from "@mui/material";
import "./studentRankingFilter.css";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from "react";


interface SearchItem {
    name: string;
    value: string;
}

const searchData: SearchItem[] = [
    {
        name: "Ration & Propotion",
        value: "Ration & Propotion"
    },
    {
        name: "Percentage",
        value: "Percentage"
    },
    {
        name: "Relations",
        value: "Relations"
    },
    {
        name: "Interesect",
        value: "Interesect"
    },
]

const SearchTextField = () => {
    const [selectedValues, setSelectedValues] = useState<any>([]);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;
    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        if (event.target.checked) {
            setSelectedValues([...selectedValues, value]);
        } else {
            setSelectedValues(selectedValues.filter((val: string) => val !== value));
        }
    };


    return (
        <Box>
            <TextField
                variant="outlined"
                size="small"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
                sx={{ backgroundColor: "#D9D9D9" }}
                aria-describedby={id}
                onClick={handleClick}
            />
            <Popper id={id} open={open} anchorEl={anchorEl} >
                <Box sx={{ zIndex: 9999, bgcolor: '#2E72F5', width: "12rem", height: "5.5rem", padding: 2, color: "white", marginTop: "0.2rem", borderRadius: "0.125rem", overflowY: "scroll" }}>
                    {searchData.map((eachData: SearchItem) => (
                        <FormGroup>
                            <FormControlLabel control={<Checkbox sx={{
                                color: "white",
                                '&.Mui-checked': {
                                    color: "white",
                                },
                            }}
                                value={eachData.value}
                                checked={selectedValues.includes(eachData.value)}
                                onChange={handleCheckboxChange}
                            />} label={eachData.name} />
                        </FormGroup>
                    ))}
                </Box>
            </Popper>
            <Grid container spacing={3} pt={1} alignItems="left">
                {selectedValues.map((value: any) => (
                    <Grid item xs={6}>
                        <Typography>
                            <Button sx={
                                {
                                    backgroundColor: "rgba(70, 161, 248, 0.30)",
                                    borderRadius: "0.125rem",
                                    fontSize: "0.7rem",
                                    whiteSpace: "nowrap"
                                }
                            }>{value} <CloseIcon /></Button>
                        </Typography>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

const StudentRankingFilters = () => {
    const [collapsed, setCollapsed] = useState<Boolean>(false)
    return (
        <Grid className="student-filter-container" p={2}>
            <Grid container>
                <Grid item xs={12}>
                    <Stack direction="row"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={3}
                    >
                        <Grid item>
                            <Typography sx={
                                {
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    color: "black",
                                    fontWeight: 600
                                }
                            }>
                                <Typography>Filter by</Typography>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox defaultChecked sx={{
                                        color: "#AE233A",
                                        '&.Mui-checked': {
                                            color: "#AE233A",
                                        },
                                    }} />} label="Employability score" />
                                </FormGroup>
                            </Typography>
                            <Typography sx={
                                {
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    color: "black",
                                    fontWeight: 600,
                                    gap: 3
                                }
                            }>
                                <Typography>Enter Cut-off Mark</Typography>
                                <FormGroup>
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        className="text-field"
                                    />
                                </FormGroup>
                            </Typography>
                        </Grid>
                        <Grid item >
                            <Typography sx={
                                {
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-around",
                                    width: "100%"
                                }
                            }>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox defaultChecked sx={{
                                        color: "#AE233A",
                                        '&.Mui-checked': {
                                            color: "#AE233A",
                                        },
                                    }} />} label="10th %" />
                                </FormGroup>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    className="text-field"
                                />
                            </Typography>
                        </Grid>
                        <Grid item >
                            <Typography sx={
                                {
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-around",
                                    width: "100%%"
                                }
                            }>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox defaultChecked sx={{
                                        color: "#AE233A",
                                        '&.Mui-checked': {
                                            color: "#AE233A",
                                        },
                                    }} />} label="12th %" />
                                </FormGroup>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    className="text-field"
                                />
                            </Typography>
                        </Grid>
                        <Grid item >
                            <Typography sx={
                                {
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-around",
                                    width: "100%%"
                                }
                            }>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox defaultChecked sx={{
                                        color: "#AE233A",
                                        '&.Mui-checked': {
                                            color: "#AE233A",
                                        },
                                    }} />} label="Graduation %" />
                                </FormGroup>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    className="text-field"
                                />
                            </Typography>
                        </Grid>
                        <Grid item
                            sx={
                                {
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "flex-end",
                                    alignItems: "center"
                                }
                            }
                        >
                            <Button sx={
                                {
                                    backgroundColor: "#AE233A",
                                    color: 'white',
                                    fontWeight: 600,
                                    textTransform: "capitalize"
                                }
                            }>Apply</Button>
                        </Grid>
                    </Stack>
                </Grid>
                {!collapsed && (<Grid item xs={12} pt={2}>
                    <Button onClick={() => setCollapsed(true)} sx={{
                        float: "right",
                        textTransform: "capitalize",
                        color: "black",
                        fontWeight: 400
                    }}>Show Advanced Filter Options <KeyboardArrowDownIcon /></Button>
                </Grid>)}
            </Grid>
            {collapsed && (<Divider sx={{ my: "1rem" }} orientation="horizontal" flexItem />)}
            <Grid container>
                {collapsed && (<RenderAdvanceFilters />)}
            </Grid>
            {collapsed && (<Grid container>
                <Grid item xs={12} pt={2}>
                    <Button onClick={() => setCollapsed(false)} sx={{
                        float: "right",
                        textTransform: "capitalize",
                        color: "black",
                        fontWeight: 400
                    }}>Collapse Advanced Filter Options<KeyboardArrowUpIcon /></Button>
                </Grid>
            </Grid>)}
        </Grid>
    )
}
const RenderAdvanceFilters = () => {
    const [selectedOptions, setSelectedOptions] = useState([]); // Track selected options

    const handleOptionSelect = (event: any, value: any) => {
        setSelectedOptions(value);
    };
    return (
        <Box width="100%">
            <Grid container spacing={5}>
                <Grid item xs={6}>
                    <Typography sx={{ color: "black", fontWeight: "600", fontSize: "1rem" }}>Select Ranges For</Typography>
                    <Box sx={
                        {
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginTop: "1rem"
                        }
                    }>
                        <Typography sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", gap: 2 }}>
                            <Typography sx={
                                {
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-around",
                                    gap: 1
                                }
                            }>
                                <Typography>Aptitude</Typography>
                                <Slider sx={{ width: "12rem" }} size="small" defaultValue={50} aria-label="Default" valueLabelDisplay="auto" />
                                <Typography>50%</Typography>
                            </Typography>
                            <Typography sx={
                                {
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-around",
                                    gap: 1
                                }
                            }>
                                <Typography>Coding</Typography>
                                <Slider sx={{ width: "12rem" }} size="small" defaultValue={50} aria-label="Default" valueLabelDisplay="auto" />
                                <Typography>50%</Typography>
                            </Typography>
                            <Typography sx={
                                {
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-around",
                                    gap: 1
                                }
                            }>
                                <Typography>English</Typography>
                                <Slider sx={{ width: "12rem" }} size="small" defaultValue={50} aria-label="Default" valueLabelDisplay="auto" />
                                <Typography>50%</Typography>
                            </Typography>
                        </Typography>
                        <Typography>or</Typography>
                        <Typography sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", gap: 2 }}>
                            <Typography sx={
                                {
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    gap: 2
                                }
                            }>
                                <Typography sx={{ fontSize: "0.8889rem" }}>Enter Cut-Off</Typography>
                                <FormGroup>
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        className="text-field"
                                    />
                                </FormGroup>
                            </Typography>
                            <Typography sx={
                                {
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    gap: 2
                                }
                            }>
                                <Typography sx={{ fontSize: "0.8889rem" }}>Enter Cut-Off</Typography>
                                <FormGroup>
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        className="text-field"
                                    />
                                </FormGroup>
                            </Typography>
                            <Typography sx={
                                {
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    gap: 2
                                }
                            }>
                                <Typography sx={{ fontSize: "0.8889rem" }}>Enter Cut-Off</Typography>
                                <FormGroup>
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        className="text-field"
                                    />
                                </FormGroup>
                            </Typography>
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <Typography sx={{ color: "black", fontWeight: "600", fontSize: "1rem" }}>Select Requirements</Typography>
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", marginTop: "1rem", gap: 4 }}>
                        <Typography sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 6, alignItems: "flex-start" }}>
                            <Typography sx={{ whiteSpace: "nowrap" }}>Tech Skills</Typography>
                            <Typography sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
                                <SearchTextField />
                            </Typography>
                        </Typography>
                        <Typography sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 4, alignItems: "flex-start" }}>
                            <Typography sx={{ whiteSpace: "nowrap" }}>Strong areas</Typography>
                            <Typography sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
                                <SearchTextField />
                            </Typography>
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default StudentRankingFilters