import { NotificationsNone, ChatBubbleOutline, Menu } from "@mui/icons-material";
import { AppBar, Toolbar, Box, Grid, Button, Badge, FormControl, Select, IconButton, useMediaQuery, useTheme, Avatar, Typography, Divider, List, ListItem, ListItemButton, ListItemText, Popover, Stack } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Images from "../../assets/images";
import SearchIcon from "@mui/icons-material/Search";
import { StyledInputBase, SearchIconWrapper, Search } from "./Header.styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
interface Props {
    name: string;
}

const Header = ({userProfile}:any) => {

    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [user, setUser] = useState<any>()

    const init = async () => {
        const userLogged: any = localStorage.getItem("user");
        if (userLogged) {
          try {
            const parsedUser = JSON.parse(userLogged);
            setUser(parsedUser);
          } catch (error) {
            console.error("Error parsing user data from localStorage:", error);
          }
        }
      }
    
      useEffect(() => {
        init();
      }, [userProfile]); 
    

    const handleLogout = () => {
        navigate("/login");
    };

    const toggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };

    const RenderMenuIcon = () => {
        return (
            <>
                {isMobile && (
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleDrawer}
                        sx={{
                            mr: 2,
                            ...(isDrawerOpen && { display: "none" }),
                            color: "black", // Set the color to black
                        }}
                    >
                        <Menu />
                    </IconButton>
                )}
            </>
        )
    };

    const RenderProfileDropdown = () => {
        const [avatarEl, setAvatarEl] = React.useState<HTMLButtonElement | null>(
            null
        );
        const handleAvatarClick = (e: React.MouseEvent<HTMLButtonElement>) => {
            setAvatarEl(e.currentTarget);
        };
        const handleAvatarClose = () => {
            setAvatarEl(null);
        };
        const open = Boolean(avatarEl);
        const id = open ? "simpe-popover" : undefined;

        return (
            <div>
                <Stack direction="row" spacing={1}>
                    <Button sx={
                        {
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "flex-start"
                        }
                    } aria-describedby={id} onClick={handleAvatarClick}>
                        <Avatar src={Images.taptapLogo}></Avatar>
                        <Typography display="flex" flexDirection="column" alignItems="flex-start" px={1}>
                            <Typography sx={{ fontSize: "0.875rem", color: "black", fontWeight: "400", textTransform: "capitalize" }}>{user?.firstName + " " + user?.lastName || "Test User"}</Typography>
                            <Typography sx={{
                                fontSize: "0.75rem",
                                fontWeight: "400",
                                color: "#3A3A3A",
                                textTransform: "capitalize"
                            }}>{user?.role || "Test role"}</Typography>
                        </Typography>
                        <KeyboardArrowDownIcon sx={{ color: "black" }} />
                    </Button>
                </Stack>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={avatarEl}
                    onClose={handleAvatarClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left"
                    }}
                >
                    <List disablePadding>
                        <ListItem disablePadding>
                            <ListItemButton sx={{ width: "10rem" }}>
                                <ListItemText primary="Logout" />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Popover>
            </div>
        );
    }

    return (
        <AppBar position="fixed" sx={{ background: "#f9f9f9" }}>
            <Toolbar>
                <RenderMenuIcon />
                <Avatar sx={{ width: 50, height: 50 }} alt="logo" src={Images.taptapLogo} ></Avatar>
                <Search sx={{ ml: 2 }}>
                    <SearchIconWrapper>
                        <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                        placeholder="Search Here (Company, Role, Skills, Category, Industry)"
                        inputProps={{ "aria-label": "search" }}
                        sx={{ width: "100%" }}
                    />
                </Search>

                <Box sx={{ flexGrow: 1 }} />
                <Box sx={{ display: { xs: "none", md: "flex" }, flexDirection: "row", justifyContent: "space-around", alignItems: "center" }}>
                    <Grid sx={{ m: 1 }}>
                        <Button
                            sx={{
                                background: "#D9D9D9",
                                color: "black",
                                borderRadius: "0.25rem",
                                opacity: 0.6,
                                fontWeight: "500",
                                textTransform: "capitalize"
                            }}
                            onClick={() => {
                                navigate("/studentranking");
                            }}
                        >
                            Student Ranking
                        </Button>
                    </Grid>

                    <Button
                        size="large"
                        aria-label="show 4 new mails"
                        sx={{ color: "black" }}
                    >
                        <Badge
                            sx={{
                                background: "#dbdbdb",
                                color: "black",
                                padding: "5px",
                                borderRadius: "0.25rem",
                                opacity: 0.6,
                                fontWeight: "500"
                            }}
                        >
                            {/* <MailIcon /> */}
                            <NotificationsNone
                                onClick={() => alert("Balckbucks Notifactions here...")}
                            />
                        </Badge>
                    </Button>

                    <Button aria-label="notifications" sx={{ color: "black" }}>
                        <Badge
                            sx={{
                                background: "#dbdbdb",
                                color: "black",
                                padding: "5px",
                                borderRadius: "0.25rem",
                                opacity: 0.6,
                                fontWeight: "500"
                            }}
                        >
                            <ChatBubbleOutline
                                onClick={() => alert("Balckbuscks Messages here...")}
                            />
                        </Badge>
                    </Button>
                    <RenderProfileDropdown />
                </Box>
            </Toolbar>
        </AppBar>
    )

}

export default Header;