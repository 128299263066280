import "./loader.css";

const Loader = () => {
  return (
    <div className="center-body">
      <div className="loader-circle-9">
        Loading
        <span></span>
      </div>
    </div>
  );
};
export default Loader;
