import { getSixMonthReport } from "../apis/api";
import * as XLSX from 'xlsx';

export const monthsValues = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

export const formatSixMonthReport = async (data: any) => {
    const aggregatedScores: any = {};
    const skillRoundCounts: any = {};
    for await (const skill of Object.keys(data.skillGroups)) {
        const rounds = data.skillGroups[skill];
        const weekMonthScores = new Map();
        const roundCounts = new Map();
        for (const roundData of rounds) {
            const week = roundData.weekOfMonth;
            const month = roundData.monthOfYear;
            const score = parseFloat(roundData.scorePercentage);
            if (week === 5) {
                const key = `4-${month}`;
                if (!weekMonthScores.has(key)) {
                    weekMonthScores.set(key, score);
                    roundCounts.set(key, 1);
                } else {
                    weekMonthScores.set(key, weekMonthScores.get(key) + score);
                    roundCounts.set(key, roundCounts.get(key) + 1);
                }
            } else {
                const key = `${week}-${month}`;
                if (!weekMonthScores.has(key)) {
                    weekMonthScores.set(key, score);
                    roundCounts.set(key, 1);
                } else {
                    weekMonthScores.set(key, weekMonthScores.get(key) + score);
                    roundCounts.set(key, roundCounts.get(key) + 1);
                }
            }
        }
        aggregatedScores[skill] = Object.fromEntries(weekMonthScores.entries());
        skillRoundCounts[skill] = Object.fromEntries(roundCounts.entries())
    }

    for await (const skill of Object.keys(aggregatedScores)) {
        const scores = aggregatedScores[skill];
        for (const key of Object.keys(scores)) {
            const totalScore = scores[key];
            const roundCountForCombination = skillRoundCounts[skill][key];
            scores[key] = (totalScore / roundCountForCombination).toFixed(1)
        }
    }
    return aggregatedScores
}

export const formatTotalScore = async (data: any) => {
    const totalScores: any = {};
    const scoreCounts: any = {};
    data.forEach((item: any) => {
        const week = item.week_of_month;
        const month = item.month_of_year;
        const score = parseFloat(item.score);

        if (week === 5) {
            if (!totalScores[`4-${month}`]) {
                totalScores[`4-${month}`] = score;
                scoreCounts[`4-${month}`] = 1;
            } else {
                totalScores[`4-${month}`] += score;
                scoreCounts[`4-${month}`]++;
            }
        } else {
            if (!totalScores[`${week}-${month}`]) {
                totalScores[`${week}-${month}`] = score;
                scoreCounts[`${week}-${month}`] = 1;
            } else {
                totalScores[`${week}-${month}`] += score;
                scoreCounts[`${week}-${month}`]++;
            }
        }
    });
    for await (const key of Object.keys(totalScores)) {
        const totalScore = totalScores[key];
        const roundCountForCombination = scoreCounts[key];
        totalScores[key] = (totalScore / roundCountForCombination).toFixed(1)
    }
    return totalScores
}

export const formatEmployabilityScore = async (data: any) => {
    const percentages: any = {};

    for await (const subject of Object.keys(data)) {
        percentages[subject] = {};
        for await (const month of Object.keys(data[subject])) {
            const totalRoundScore = data[subject][month].total_round_score;
            const totalUserScore = data[subject][month].total_user_score;
            const percentage = (totalUserScore / totalRoundScore) * 100;
            const formatedMonth = parseInt(month.split("-")[1])
            percentages[subject][formatedMonth] = percentage;
        }
    }
    return percentages
}

//function to calculate employabilty band
export function calculateEmployabilityBand(coding: number, aptitude: number, english: number) {
    const result: any = {}
    if (aptitude === 0) {
        result["Band"] = "";
        result["Comment"] = "Insufficient Data. To start with you should write one test in Logical and Language";
        result["Revised"] = "";
        result["ImprovementAreas"] = "";
    } else {
        if (coding > 90) {
            if (aptitude > 70) {
                if (english > 70) {
                    result["Band"] = "P";
                    result["Comment"] = "Congratulations ! You are a star. You will be eligible for Products Companies.";
                    result["Revised"] = "";
                    result["ImprovementAreas"] = "Add relevant certifications to your profile";
                } else if (english > 50) {
                    result["Band"] = "S";
                    result["Comment"] = "Congratulations ! You are in right direction. You can try for Services Company Software jobs.";
                    result["Revised"] = "Congratulations! You are eligible to apply for Product Companies. You are in the right direction. Keep up your language skills";
                    result["ImprovementAreas"] = "You can do better if you improve language skills. (Give link to LSRW courses) and do certifications (Give link to coding courses)";
                } else {
                    result["Band"] = "X";
                    result["Comment"] = "Very few selective companies who need only programming skills. Employability is poor though you have excellent coding skills. You might fail in interviews.";
                    result["Revised"] = "You can still apply for Product Based Roles. Make sure you work on your communication";
                    result["ImprovementAreas"] = "Concentrate on your language skills (Give link to LSRW courses)";
                }
            } else if (aptitude > 50) {
                result["Band"] = "X";
                result["Comment"] = "Very few selective companies who need only programming skills. Employability is poor though you have excellent coding skills. You might fail in Aptitude tests.";
                result["Revised"] = "Dont lose hope on getting a higher package roles. Many Service companies look for candidates who have high Quantitative Ability with reasonable logical skills, Language Skills with basic coding skills";
                result["ImprovementAreas"] = "Improve your Logical (Give link) and language skills (Give link) to be eligible for Products / Services Companies";
            } else {
                result["Band"] = "F";
                result["Comment"] = "Employability is poor though you have excellent coding skills. You might fail in Aptitude tests.";
                result["Revised"] = "Employability is poor though you have excellent coding skills. You might fall short in few marks to get shortlists for Software roles in Service companies";
                result["ImprovementAreas"] = "Large effort is required in logical and aptitude areas";
            }
        } else if (coding > 70) {
            if (aptitude > 70) {
                if (english > 50) {
                    result["Band"] = "S";
                    result["Comment"] = "You are eligible for IT and Software based Service Organizations";
                    result["Revised"] = "";
                    result["ImprovementAreas"] = "Improve your coding skills (links) and get Blackbucks certifications to move to next band";
                } else {
                    result["Band"] = "F";
                    result["Comment"] = "Employability is poor. You might fail in Aptitude tests.";
                    result["Revised"] = "You can still apply for few selected software roles. Do not fall back on the logical skills. ";
                    result["ImprovementAreas"] = "Improve your basic logical and reasoning skills (give link)";
                }
            } else {
                result["Band"] = "X";
                result["Comment"] = "Very few selective companies who need only programming skills. Employability is poor though you have decent coding skills. You might fail in Aptitude tests.";
                result["Revised"] = "Employability is poor. You might fail in Aptitude tests. Difficult to get shortlists from Product Companies or Service Companies. ";
                result["ImprovementAreas"] = "Improve your basic logical and reasoning skills (give link)";
            }
        } else {
            if (english > 70) {
                if (aptitude > 70) {
                    result["Band"] = "M";
                    result["Comment"] = "Suitable for Managerial / Analyst roles.";
                    result["Revised"] = "";
                    result["ImprovementAreas"] = "Suggested Business Analyst / Product Analyst / Business Development Manager Roles. If you are looking for high employability in IT Sector, you have to learn coding skills ";
                } else {
                    result["Band"] = "BPO";
                    result["Comment"] = "Suitable for BPO Roles";
                    result["Revised"] = "";
                    result["ImprovementAreas"] = "Improve your Language skills to be selected in BPO Companies. If you are looking for high employability in IT Sector, you have to learn coding skills ";
                }
            } else {
                result["Band"] = "F";
                result["Comment"] = "Employability is poor.";
                result["Revised"] = "";
                result["ImprovementAreas"] = "Your learning path will be Step 1: Learn Aprtitude and Reasoning Step 2: Learn Languge Skills Step 3: Learn Programming Skills";
            }
        }
    }
    return result["Band"] ? result["Band"] : "NA"
}

export const downloadSixMonthReport = async (end_date: number, user_id: number, user: any, monthData: any) => {
    const sixMonthData = (await getSixMonthReport(end_date, user_id)).data;
    const workBook = await generateExcelWorksheet(sixMonthData, user, monthData)
    XLSX.writeFile(workBook, `${user.id}.xlsx`, { compression: true });
}


export const generateExcelWorksheet = async (sixMonthData: any, user: any, monthData: any) => {
    const formatedMonthScore = await formatSixMonthReport(sixMonthData);
    const formatedTotalScore = await formatTotalScore(sixMonthData["totalHackathonScore"]);
    const formatedEmployabilityScore = await formatEmployabilityScore(sixMonthData["employabilityBand"]);
    const monthsIndexs: any[] = []
    for (let i = 0; i < monthData.length; i++) {
        monthsIndexs.push(monthsValues.indexOf(monthData[i]))
    }

    const excelObject: any[] = [];
    //User Row
    const userObject: any = {
        "Student Name": user.firstName + " " + user.lastName,
        "Email": user.email,
        "Black Bucks Id": user.id,
        "Mobile No": user.phone,
        "College Id": user.college.id,
        "Department Name": user.department.name,
        "No Of Tests Taken": user.numberOfTestsTaken,
    }
    excelObject.push(userObject)
    const worksheet = XLSX.utils.json_to_sheet(excelObject, { origin: { r: 0, c: 0 } });
    worksheet['!rows'] = worksheet['!rows'] || []
    worksheet['!rows'][0] = { hpx: 20 };

    // Monthly Headings  Row
    // Row starts from 3 and column 1 
    const monthlyHeadingsObj: any = {}
    for (let i = 0; i < monthData.length; i++) {
        for (let j = 0; j < 4; j++) {
            const key = `${i}_${j}_${monthData[i]}`;
            if (!monthlyHeadingsObj[key]) {
                monthlyHeadingsObj[key] = monthData[i] + `(Week ${j + 1})`
            }
        }
    }
    const monthsHeadingsRow: any[] = []
    monthsHeadingsRow.push(monthlyHeadingsObj)
    XLSX.utils.sheet_add_json(worksheet, monthsHeadingsRow, { skipHeader: true, origin: { r: 3, c: 1 } })
    worksheet['!rows'][3] = { hpx: 20 };

    // Aptitude,Coding,English week rows
    // Row start from 4 and column 0
    const skills: any[] = ["Aptitude", "Coding", "English"]
    skills.forEach((eackSkill, index) => {
        const weekRowsArray: any[] = []
        const weekRows: any = { '0': eackSkill }
        monthsIndexs.forEach((month, monthIndex) => {
            for (let j: any = 1; j < 5; j++) {
                const key = `${j}-${month}`;
                if (!weekRows[key]) {
                    weekRows[key] = formatedMonthScore[eackSkill]?.[key] || "NA"
                }
            }
        })
        weekRowsArray.push(weekRows)
        console.log(weekRowsArray, eackSkill)
        XLSX.utils.sheet_add_json(worksheet, weekRowsArray, { skipHeader: true, origin: { r: 4 + index, c: 0 } })
    })

    // Total Week Row
    // Row start from 8 and column 0
    function totalWeekRows() {
        const weekRowsArray: any[] = []
        const weekRows: any = { "0": "Total" }
        monthsIndexs.forEach((month, monthIndex) => {
            for (let j: any = 1; j < 5; j++) {
                const key = `${j}-${month}`;
                if (!weekRows[key]) {
                    weekRows[key] = formatedTotalScore?.[key] || "NA"
                }
            }
        })
        weekRowsArray.push(weekRows)
        XLSX.utils.sheet_add_json(worksheet, weekRowsArray, { skipHeader: true, origin: { r: 8, c: 0 } })
    }
    totalWeekRows();


    // Monthly Headings  Row
    // Row starts from 3 and column 1 
    const swenMonthlyHeadingsObj: any = {}
    for (let i = 0; i < monthData.length; i++) {
        const key = `${i}_${monthData[i]}`;
        if (!swenMonthlyHeadingsObj[key]) {
            swenMonthlyHeadingsObj[key] = monthData[i];
        }
    }
    const swenMonthsHeadingsRow: any[] = []
    swenMonthsHeadingsRow.push(swenMonthlyHeadingsObj)
    XLSX.utils.sheet_add_json(worksheet, swenMonthsHeadingsRow, { skipHeader: true, origin: { r: 11, c: 1 } })
    worksheet['!rows'][11] = { hpx: 20 };

    // Employability Band  Week Row
    // Row start from 12 and column 0
    function employabilityWeekRows() {
        const weekRowsArray: any[] = []
        const weekRows: any = { "0": "Employability Band" }
        monthsIndexs.forEach((month, monthIndex) => {
            const key = `${month}`;
            if (!weekRows[key]) {
                weekRows[key] = calculateEmployabilityBand(
                    formatedEmployabilityScore?.['Coding']?.[key],
                    formatedEmployabilityScore?.['Aptitude']?.[key],
                    formatedEmployabilityScore?.['English']?.[key]
                ) || "NA"
            }
        })
        weekRowsArray.push(weekRows)
        XLSX.utils.sheet_add_json(worksheet, weekRowsArray, { skipHeader: true, origin: { r: 12, c: 0 } })
    }
    employabilityWeekRows();

    // Strengths,weakness,improvements Week Row
    // Row start from 14 and column 0
    function swenWeekRows() {
        const swen: any[] = ["strengths", "weaknesses", "needsImprovement"]
        for (let k = 0; k < 3; k++) {
            const weekRowsArray: any[] = []
            const weekRows: any = { "0": swen[k] }
            monthsIndexs.forEach((month, monthIndex) => {
                const key = `${month}-${swen[k]}`;
                if (!weekRows[key]) {
                    const txtSwen: any[] = sixMonthData?.[swen[k]]?.[`month${month}`]
                    weekRows[key] = txtSwen ? txtSwen.join(",") : "NA"
                }
            })
            weekRowsArray.push(weekRows)
            XLSX.utils.sheet_add_json(worksheet, weekRowsArray, { skipHeader: true, origin: { r: 14 + k, c: 0 } })
        }
    }
    swenWeekRows();


    const workbook = XLSX.utils.book_new();
    worksheet['!cols'] = [];
    for (var i = 0; i < 30; i++) {
        // Set the same width for all columns (defaultWidth characters)
        worksheet['!cols'].push({ wch: 20 });
    }
    XLSX.utils.book_append_sheet(workbook, worksheet, "User Six Month Report");
    return workbook
}


