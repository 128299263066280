import { Button, Grid, Typography } from '@mui/material'
import Header from '../Header/Header';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import "./studentRanking.css";
import StudentRankingFilters from '../StudentRankingFilters/StudentRankingFilters';
import StudentRankingTable from '../StudentRankingTable/StudentRankingTable';

function StudentRanking() {
  return (
    <>
      <Header/>
      <Grid container mt={6}>
        <Grid item xs={12} paddingTop={2} px={3} sx={
          {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: "space-between"
          }
        }>
          <Typography className='student-header' fontSize="1.5rem">Student Rankings</Typography>
          <Button className='export-label' sx={{ textTransform: "capitalize" }}><ExitToAppIcon sx={{ paddingRight: "0.6rem", textTransform: "capitalize" }} />Export Page as Link</Button>
        </Grid>
        <Grid item xs={12} paddingTop={2} px={3}>
          <StudentRankingFilters />
        </Grid>
        <Grid item xs={12} paddingTop={2} px={3}>
          <StudentRankingTable />
        </Grid>
      </Grid>
    </>
  )
}

export default StudentRanking