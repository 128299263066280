import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import './profilingModal.css';
import ProfilingModalForm from '../ProfilingModalForm/ProfilingModalForm';
import { Grid, List, ListItem, ListItemText, Typography } from '@mui/material';
import Images from '../../assets/images';
import { useAppSelector } from '../../redux/hooks/hooks';
import { useEffect, useState } from 'react';
import ProfilingModalEachCard from '../ProfilingModalEachCard/ProfilingModalEachCard';


const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    p: 4,
};

const profilingModalData = [
    {
        skill: "Aptitude",
        background: '#88E53E',
        dataBackground: "#C0E8C0",
        data: [
            { content: '10' },
            { content: '7.1' },
            { content: 'Number,Numerical Ability Ages' },
            { content: 'No Weak Area' },
            { content: '', },
        ]
    },
    {
        skill: "Coding",
        background: '#A7C2FF',
        dataBackground: "#E1E7FF",
        data: [
            { content: '10' },
            { content: '7.1' },
            { content: 'Number,Numerical Ability Ages' },
            { content: 'No Weak Area' },
            { content: '', },
        ]
    },
    {
        skill: "English",
        background: '#88E53E',
        dataBackground: "#C0E8C0",
        data: [
            { content: '10' },
            { content: '7.1' },
            { content: 'Number,Numerical Ability Ages' },
            { content: 'No Weak Area' },
            { content: '', },
        ]
    }
]

const ProfilingHeadings = [
    { name: "Section Score" },
    { name: "Batch Avg Score" },
    { name: "Strength" },
    { name: "Weakness" },
    { name: "Total Score" },
]

export default function ProfilingModal({ modalOpen, handleClose, selectedUserRowId }: any) {
    const [formData, setFormData] = useState({});
    const closeModal = () => {
        handleClose();
    }
    const reportsData = useAppSelector(
        (state) => state.reportsReducer.reportsData
    );
    const reportMonths = useAppSelector(
        (state) => state.reportsReducer.reportEndMonth
    );
    const userData: any = useAppSelector(
        (state) => state.reportsReducer.user
    ).userData

    useEffect(() => {
        if (reportsData && selectedUserRowId) {
            const result = reportsData.find((item: any) => item.id === selectedUserRowId.toString());
            setFormData(result);
        }
    }, [selectedUserRowId])
    return (
        <div>
            <Modal
                open={modalOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className='modal-container'>
                        <Grid container>
                            <Grid
                                item
                                xs={12}
                                display="flex"
                                justifyContent="space-between"
                                sx={{ m: 1, mr: 2, mt: 2 }}
                            >
                                <Typography
                                    variant="h4"
                                    sx={{
                                        color: "#0A065C",
                                        fontWeight: "bold",
                                        mt: 4,
                                        fontFamily: "Maven Pro",
                                    }}
                                >
                                    Blackbucks Profiling Report
                                </Typography>
                                <img src={Images.taptapLogo} width="86px" height="86px" />
                            </Grid>
                            <Grid item xs={12}>
                                <ProfilingModalForm propData={formData} userObject={userData} />
                            </Grid>
                        </Grid>
                        <Grid container sx={{ paddingTop: "3rem" }} spacing={3}>
                            <Grid item display="flex" flexDirection="column" gap={3} xs={2} mt={3}>
                                {ProfilingHeadings.map((data: any, i: number) => (
                                    <List className='text-con' sx={{
                                        height: "5.8rem",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                    }}>
                                        <ListItem>
                                            <ListItemText>{data.name}</ListItemText>
                                        </ListItem>
                                    </List>
                                ))}
                            </Grid>
                            <Grid item xs={10} display="flex" flexDirection="row" justifyContent="space-between" gap={2}>
                                {
                                    profilingModalData.map((data: any, i: number) => (
                                        <ProfilingModalEachCard key={i} propsData={data} />
                                    ))
                                }
                            </Grid>
                        </Grid>
                    </div>
                    <button className='close-icon' onClick={() => closeModal()}>X</button>
                </Box>
            </Modal>
        </div>
    );
}