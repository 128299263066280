import React from "react";
import {
    Box,
    Grid,
    Typography,
} from "@mui/material";
import { useAppSelector } from "../../redux/hooks/hooks";
import { HowToReg, OndemandVideo, Quiz, ScienceOutlined, TextSnippet } from "@mui/icons-material";
import DashboardCard from "../DashboardCard/DashboardCard";
import DashboardTable from "../DashboardTable/DashboardTable"

const stasData = [
    {
        title: "Assessment Conducted",
        value: 200,
        icon: <TextSnippet fontSize="large"
            sx={{ color: "#2E72F5", mr: 1, mt: 1 }} />
    },
    {
        title: "Question Tested On",
        value: 120,
        icon: <Quiz fontSize="large"
            sx={{ color: "#2E72F5", mr: 1, mt: 1 }} />
    },
    {
        title: "Attendance Percentage",
        value: 20,
        icon: <HowToReg fontSize="large"
            sx={{ color: "#2E72F5", mr: 1, mt: 1 }} />
    },
    {
        title: "Blackbucks Trainings",
        value: 180,
        icon: <OndemandVideo fontSize="large"
            sx={{ color: "#2E72F5", mr: 1, mt: 1 }} />
    },
    {
        title: "Blackbucks Virtual Labs",
        value: 150,
        icon: <ScienceOutlined fontSize="large"
            sx={{ color: "#2E72F5", mr: 1, mt: 1 }} />
    }
]

const Dashboard = () => {
    const statData: any = useAppSelector(
        (state) => state.reportsReducer.stat.statData
    );

    const RenderDashboardCards = () => {
        return (
            <Grid container>
                <Grid item>
                    <Typography sx={{ p: 2, fontWeight: "600", fontSize: "1.5rem" }} variant="h4">
                        My Dashboard
                    </Typography>
                </Grid>

                <Grid container>
                    {stasData.map((eachData: any, index: number) => (
                        <DashboardCard key={index} cardData={eachData} />
                    ))}
                </Grid>
            </Grid>
        );
    }

    return (
        <Box width="100%" sx={{ background: "#F5F5F5", mt: 5 }}>
            <RenderDashboardCards />
            <DashboardTable />
        </Box>
    )
}

export default Dashboard