import { Box, Typography } from "@mui/material"
import "./profilingModalEachCard.css";

interface propsInterface {
    skill: string,
    background: string,
    dataBackground: string,
    data: [
        { content: string }
    ]
}

const ProfilingModalEachCard = ({ propsData }: { propsData: propsInterface }) => {
    return (
        <Box sx={{ width: '50%' }}>
            <Typography align="center" className="month-label">
                <Typography className="month-text" sx={{ backgroundColor: propsData.background }} >{propsData.skill}</Typography>
            </Typography>
            <Box display="flex" flexDirection="column" gap={3.4} >
                {propsData.data.map((eachData: any) => (
                    <Typography sx={{
                        backgroundColor: propsData.dataBackground,
                        padding: "0.5rem",
                        height: "5.6rem",
                        borderRadius: "0.8995rem",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#045139",
                        fontSize: "1rem",
                        fontWeight: 300
                    }}>
                        {eachData.content}
                    </Typography>
                ))}
            </Box>
        </Box>
    )
}

export default ProfilingModalEachCard