import { Box, Grid, TextField, Typography } from '@mui/material'
import Images from '../../assets/images';
import { useState, useEffect } from 'react';

const userReportData = {
    student_name: "",
    phone: "",
    email: "",
    bbId: "",
}


function ProfilingModalForm({ propData,userObject }: any) {
    const [userData, setSingleUserReportData] = useState(userReportData)
    useEffect(() => {
        if (propData) {
            const formattedData = {
                student_name: propData.firstName + " " + propData.lastName,
                phone: propData.phone,
                email: propData.email,
                bbId: propData.id,
            }
            setSingleUserReportData(formattedData);
        }
    }, [propData])

    return (
        <Grid container sx={{ p: 1 }} spacing={2}>
            <Box sx={{ flexGrow: 1, width: '100%', ml: 2 }}>
                <Grid container sx={{ padding: '15px' }} spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <Typography sx={{ color: '#045139', fontFamily: 'Maven Pro' }}>
                            Student Name
                        </Typography>
                        <TextField fullWidth value={userData?.student_name === "" ? "NA" : userData?.student_name} type='text' name='College Code' variant='outlined' size='small' className="formField"
                            sx={{ input: { color: "#045139", fontWeight: "600" } }} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography sx={{ color: '#045139', fontFamily: 'Maven Pro' }}>
                            Email
                        </Typography>
                        <TextField fullWidth value={userData?.email === "" ? "NA" : userData?.email} type='text' name='College Code' variant='outlined' size='small' className="formField"
                            sx={{ input: { color: "#045139", fontWeight: "600" } }} />
                    </Grid>

                    <Grid item xs={12} sm={2}>
                        <Typography sx={{ color: '#045139', fontFamily: 'Maven Pro' }}>
                            Blackbucks ID
                        </Typography>
                        <TextField fullWidth value={userObject['userDetails']?.bbId === undefined ? 'NA' : userObject['userDetails']?.bbId} type='text' name='College Code' variant='outlined' size='small' className="formField"
                            sx={{ input: { color: "#045139", fontWeight: "600" } }} />
                    </Grid>
                </Grid>
            </Box>
        </Grid>
    )
}

export default ProfilingModalForm;