import { Grid, Typography } from '@mui/material'

function LabReport() {
  return (
  <Grid>
    <Typography variant='h4'>Coming Soon...</Typography>
  </Grid>
  )
}

export default LabReport