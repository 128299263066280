import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import './sixMonthReportModal.css';
// import ReportScreen from '../../screens/app/report.screen';
import SixMonthReportForm from '../SixMonthReportForm/SixMonthReportForm';
import { useAppSelector } from '../../redux/hooks/hooks';
import { useEffect, useState } from 'react';
import { Container, Divider, Grid, List, ListItem, ListItemText, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import Images from '../../assets/images';
import SixMonthEachCard from '../SixMonthReportEachCard/SixMonthEachCard';
import { monthsValues, formatEmployabilityScore, formatSixMonthReport, formatTotalScore } from "../../common/sixMonthReportScripts";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    p: 4,
};

interface Item {
    id: number;
    month: string;
    backgroundColor: string;
    monthColor: string;
    monthValue: number;
}

const TableData: any = [
    [
        { name: "Aptitude" },
        { name: "Coding" },
        { name: "English" }
    ],
    [
        { name: "Total" },
        { name: "Employability Band" }
    ],
    [
        { name: "Strengths" }
    ],
    [
        { name: "Weakness" }
    ],
    [
        { name: "Needs Improvement" }
    ],
    [
        { name: "Parent/TPO Signature" }
    ]
]



export default function SixMonthReportModal({ modalOpen, handleClose, selectedUserRowId }: any) {
    const [formData, setFormData] = useState<any>({});
    const [sixMonthFormatData, setSixMonthFormatData] = useState({})
    const [formatedTotalScoreData, setFormatedTotalScoreData] = useState({});
    const [formatedEmployabilityScoreData, setFormatedEmployabilityScoreData] = useState({})
    const closeModal = () => {
        handleClose();
    }
    const reportsData = useAppSelector(
        (state) => state.reportsReducer.reportsData
    );
    const reportMonths = useAppSelector(
        (state) => state.reportsReducer.reportEndMonth
    );

    const userData: any = useAppSelector(
        (state) => state.reportsReducer.user
    ).userData

    const sixMonthReportData = useAppSelector(
        (state) => state.reportsReducer.sixMonthReport
    );

    const monthsData: Item[] = [
        {
            id: 1,
            month: reportMonths[0],
            backgroundColor: "#C0E8C0",
            monthColor: "#88E53E",
            monthValue: monthsValues.indexOf(reportMonths[0])
        },
        {
            id: 2,
            month: reportMonths[1],
            backgroundColor: "#E1E7FF",
            monthColor: "#A7C2FF",
            monthValue: monthsValues.indexOf(reportMonths[1])
        },
        {
            id: 3,
            month: reportMonths[2],
            backgroundColor: "#C0E8C0",
            monthColor: "#88E53E",
            monthValue: monthsValues.indexOf(reportMonths[2])
        },
        {
            id: 4,
            month: reportMonths[3],
            backgroundColor: "#E1E7FF",
            monthColor: "#A7C2FF",
            monthValue: monthsValues.indexOf(reportMonths[3])
        },
        {
            id: 5,
            month: reportMonths[4],
            backgroundColor: "#C0E8C0",
            monthColor: "#88E53E",
            monthValue: monthsValues.indexOf(reportMonths[4])
        },
        {
            id: 6,
            month: reportMonths[5],
            backgroundColor: "#E1E7FF",
            monthColor: "#A7C2FF",
            monthValue: monthsValues.indexOf(reportMonths[5])
        },
    ];

    useEffect(() => {
        if (reportsData && selectedUserRowId) {
            let result = reportsData.find((item: any) => item.id === selectedUserRowId.toString());
            setFormData(result);
        }
        const fetchData = async () => {
            try {
                const formattedData = await formatSixMonthReport(sixMonthReportData);
                setSixMonthFormatData(formattedData)
            } catch (error) {
                console.error('Error fetching and formatting data:', error);
            }
        };
        const formatedTotalScore = async () => {
            try {
                const formattedData = await formatTotalScore(sixMonthReportData["totalHackathonScore"]);
                setFormatedTotalScoreData(formattedData)
            } catch (error) {
                console.error('Error fetching and formatting data:', error);
            }
        }
        const formatedEmployabilityScore = async () => {
            try {
                const formattedData = await formatEmployabilityScore(sixMonthReportData["employabilityBand"]);
                setFormatedEmployabilityScoreData(formattedData);
            } catch (error) {
                console.error('Error fetching and formatting data:', error);
            }
        }
        if (sixMonthReportData) {
            fetchData();
            formatedTotalScore();
            formatedEmployabilityScore();
        }
    }, [selectedUserRowId, sixMonthReportData])
    return (
        <div>
            <Modal
                open={modalOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className='modal-container'>
                        <Grid container>
                            <Grid
                                item
                                xs={12}
                                display="flex"
                                justifyContent="space-between"
                                sx={{ m: 1, mr: 2, mt: 2 }}
                            >
                                <Typography
                                    variant="h4"
                                    sx={{
                                        color: "#0A065C",
                                        fontWeight: "bold",
                                        mt: 4,
                                        fontFamily: "Maven Pro",
                                    }}
                                >
                                    Blackbucks Report
                                </Typography>
                                <img src={Images.taptapLogo} width="86px" height="86px" />
                            </Grid>
                            <Grid item xs={12}>
                                <SixMonthReportForm propData={formData} userObject={userData} />
                            </Grid>
                        </Grid>
                        <Stack direction="column" overflow="scroll">
                            <Stack direction="row" mt={4} spacing={2}>
                                <Box>
                                    <TableContainer className="table-container" sx={{ marginTop: "1.5rem", backgroundColor: "rgba(10, 6, 92, 0.05)" }}>
                                        <Table sx={{ width: "12rem" }}>
                                            <TableBody sx={{ height: "7rem" }}>
                                                <TableRow>
                                                    <TableCell colSpan={12} sx={{ whiteSpace: "nowrap" }}>Aptitude (%)</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell colSpan={12} sx={{ whiteSpace: "nowrap" }}>Coding (%)</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell colSpan={12} sx={{ whiteSpace: "nowrap" }}>English (%)</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                                {monthsData.map((monthData: any, index: number) => (
                                    <>
                                        <SixMonthEachCard key={index} propsData={monthData} sixMonthFormatData={sixMonthFormatData} rows={3} />
                                    </>
                                ))}
                            </Stack>
                            <Stack direction="row" mt={4} spacing={2}>
                                <Box>
                                    <TableContainer className="table-container" sx={{ backgroundColor: "rgba(10, 6, 92, 0.05)" }}>
                                        <Table sx={{ width: "12rem" }}>
                                            <TableBody sx={{ height: "7rem" }}>
                                                <TableRow>
                                                    <TableCell colSpan={12} sx={{ whiteSpace: "nowrap" }}>Total (%)</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell colSpan={12} sx={{ whiteSpace: "nowrap" }}>Employability Band</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                                {monthsData.map((monthData: any, index: number) => (
                                    <>
                                        <SixMonthEachCard formatedTotalScoreData={formatedTotalScoreData} formatedEmployabilityScore={formatedEmployabilityScoreData} propsData={monthData} rows={2} />
                                    </>
                                ))}
                            </Stack>
                            <Stack direction="row" mt={4} spacing={2}>
                                <Box>
                                    <TableContainer className="table-container" sx={{ backgroundColor: "rgba(10, 6, 92, 0.05)" }}>
                                        <Table sx={{ width: "12rem" }}>
                                            <TableBody sx={{ height: "7rem" }}>
                                                <TableRow>
                                                    <TableCell colSpan={12} sx={{ whiteSpace: "nowrap" }}>Strength</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                                {sixMonthReportData && (
                                    <>
                                        {monthsData.map((monthData: any, index: number) => (
                                            <>
                                                <SixMonthEachCard propsData={monthData} swenData={sixMonthReportData["strengths"]} rows={1} />
                                            </>
                                        ))}
                                    </>
                                )}
                            </Stack>
                            <Stack direction="row" mt={4} spacing={2}>
                                <Box>
                                    <TableContainer className="table-container" sx={{ backgroundColor: "rgba(10, 6, 92, 0.05)" }}>
                                        <Table sx={{ width: "12rem" }}>
                                            <TableBody sx={{ height: "7rem" }}>
                                                <TableRow>
                                                    <TableCell colSpan={12} sx={{ whiteSpace: "nowrap" }}>Weaknesses</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                                {sixMonthReportData && (
                                    <>
                                        {monthsData.map((monthData: any, index: number) => (
                                            <>
                                                <SixMonthEachCard propsData={monthData} swenData={sixMonthReportData["weaknesses"]} rows={1} />
                                            </>
                                        ))}
                                    </>
                                )}
                            </Stack>
                            <Stack direction="row" mt={4} spacing={2}>
                                <Box>
                                    <TableContainer className="table-container" sx={{ backgroundColor: "rgba(10, 6, 92, 0.05)" }}>
                                        <Table sx={{ width: "12rem" }}>
                                            <TableBody sx={{ height: "7rem" }}>
                                                <TableRow>
                                                    <TableCell colSpan={12} sx={{ whiteSpace: "nowrap" }}>Needs Improvement</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                                {sixMonthReportData && (
                                    <>
                                        {monthsData.map((monthData: any, index: number) => (
                                            <>
                                                <SixMonthEachCard propsData={monthData} swenData={sixMonthReportData["needsImprovement"]} rows={1} />
                                            </>
                                        ))}
                                    </>
                                )}
                            </Stack>
                            <Stack direction="row" mt={4} spacing={2}>
                                <Box>
                                    <TableContainer className="table-container" sx={{ backgroundColor: "rgba(10, 6, 92, 0.05)" }}>
                                        <Table sx={{ width: "12rem" }}>
                                            <TableBody sx={{ height: "7rem" }}>
                                                <TableRow>
                                                    <TableCell colSpan={12} sx={{ whiteSpace: "nowrap" }}>Parent/TPO Signature</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                                {sixMonthReportData && (
                                    <>
                                        {monthsData.map((monthData: any, index: number) => (
                                            <>
                                                <SixMonthEachCard propsData={monthData} rows={1} />
                                            </>
                                        ))}
                                    </>
                                )}
                            </Stack>
                        </Stack>
                    </div>
                    <button className='close-icon' onClick={() => closeModal()}>X</button>
                </Box>
            </Modal>
        </div>
    );
}

